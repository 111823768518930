import { createStore } from 'vuex';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
// import jwt from 'jsonwebtoken';

const localStorage = window.localStorage;

const store = createStore({
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
    activeSidebarButton: 'itemListButton',
    activeContent: 'itemListContent',
    itemData: [],
    allDatas: [],
    searchData: [],
    searchDataAll: [],
    isSearching: false,
    searchQueries: {
      searchNumber:'',
      searchInc:'',
      searchBefore:'',
      searchAfter:'',
      searchPartNumber:'',
      searchMfr:'',
      searchFlag1:'',
      searchStatus: '',
      searchAll:'',
    },
    
    page: 1,
    searchPage: 1,
    perPage: 350,
    inc: [],
    user:[],
    userRole:[],
    selectedRow:[],
    attributes: [],
    globalAttributes: [],
    groups:[],
    isLoggedIn: false
  },

  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    setLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = null;
      localStorage.removeItem('userInfo');
      window.location.reload();
    },
    setActiveButton(state, buttonName) {
      state.activeSidebarButton = buttonName;
    },
    setActiveContent(state, contentName) {
      state.activeContent = contentName;
    },
    updateItemAttributes(state, { newItemData }) {
      const itemToUpdate = state.itemData.find(item => item.no === newItemData.no);
      if (itemToUpdate) {
        Object.assign(itemToUpdate, newItemData);
      }
    },
    SET_ITEM_DATA(state, data) {
      if (Array.isArray(data)) {
        state.itemData = [...state.itemData, ...data];
      } else {
        console.error('Data is not an array');
        // Handle error accordingly, e.g., show error message to user or revert state changes
      }
    },    

    SET_ALL_DATA(state, data) {
      state.allDatas = data
      console.log('data store', state.allDatas);
    },

    SET_SEARCH_DATA(state, data) {
      state.searchData = [...state.searchData, ...data];
      state.isSearching = true;
    },

    SET_SEARCH_ALL_DATA(state, data) {
      state.searchDataAll = [...state.searchDataAll, ...data];
      state.isSearching = true;
    },

    RESET_SEARCH_DATA(state) {
      state.searchData = []
      state.isSearching = false;
    },

    RESET_SEARCH_DATA_ALL(state) {
      state.searchDataAll = []
    },

    SET_PAGE(state, page) {
      state.page = page;
    },
    
    SET_SEARCH_PAGE(state, page) {
      state.searchPage = page
    },

    SET_SEARCH_QUERY(state, { key, query }) {
      state.searchQueries[key] = query;
    },
    SET_INC_DATA(state, data) {
      state.inc = data
    },
    SET_USER_DATA(state, data) {
      state.user = data
    },
    SET_USER_ROLE(state, data) {
      state.userRole = data
    },
    SET_ATTRIBUTES(state, data) {
      state.attributes = data
    },
    SET_GLOBAL_ATTRIBUTES(state, data) {
      state.globalAttributes = data
    },
    SET_GROUPS(state, data) {
      state.groups = data
    },
    SET_SELECTED_ROW(state, row) {
      state.selectedRow = row;
    },
    UPDATE_MATERIAL_DATA(state, updatedData) {
      const index = state.itemData.findIndex(item => item.id === updatedData.id);
      if (index !== -1) {
        state.itemData.splice(index, 1, updatedData);
      }
    },
    BULK_UPDATE_INC() {
      console.log('Bulk inc berhasil')
    },
    BULK_UPDATE_CAT() {
      console.log('Bulk cat berhasil')
    },
    BULK_UPDATE_QC() {
      console.log('Bulk qc berhasil')
    },
    ADD_NEW_ATTRIBUTE(){
      console.log('Attribute Berhasil ditambahkan');
    },
    ADD_NEW_GLOBAL(){
      console.log('Global Attribute Berhasil ditambahkan');
    },
    ADD_NEW_INC(state, data){
      state.inc.push(data)
    },
    ADD_NEW_GROUP(){
      console.log('Group Berhasil ditambahkan');
    },
    ADD_NEW_USER(){
      console.log('User Berhasil ditambahkan');
    },
    BULK_INSERT_INC() {
      console.log("Adding new INC to the list of INCs");
    },
    BULK_INSERT_GROUP() {
      console.log("Adding new group to the list of groups");
    },
    DELETE_MASTER_ATTRIBUTE(state, deletedId) {
      const index = state.attributes.findIndex(item => item.id === deletedId);
      if (index !== -1) {
        state.attributes.splice(index, 1);
      }
    },
    DELETE_MASTER_GLOBAL(state, deletedId) {
      const index = state.globalAttributes.findIndex(item => item.id === deletedId);
      if (index !== -1) {
        state.globalAttributes.splice(index, 1);
      }
    },
    DELETE_MASTER_INC(state, deletedId) {
      const index = state.inc.findIndex(item => item.id === deletedId);
      if (index !== -1) {
        state.inc.splice(index, 1);
      }
    },
    DELETE_MASTER_GROUP(state, deletedId) {
      const index = state.groups.findIndex(item => item.id === deletedId);
      if (index !== -1) {
        state.groups.splice(index, 1);
      }
    },
    UPDATE_MASTER_ATTRIBUTE(state, updatedAttribute) {
      const index = state.attributes.findIndex(item => item.id === updatedAttribute.id);
      if (index !== -1) {
        state.attributes.splice(index, 1, updatedAttribute);
      }
    },
    UPDATE_MASTER_GLOBAL(state, updatedGlobal) {
      const index = state.globalAttributes.findIndex(item => item.id === updatedGlobal.id);
      if (index !== -1) {
        state.globalAttributes.splice(index, 1, updatedGlobal);
      }
    },
    UPDATE_MASTER_INC(state, updatedInc) {
      const index = state.inc.findIndex(item => item.id === updatedInc.id);
      if (index !== -1) {
        state.inc.splice(index, 1, updatedInc);
      }
    },
    UPDATE_MASTER_GROUP(state, updatedGroup) {
      const index = state.groups.findIndex(item => item.id === updatedGroup.id);
      if (index !== -1) {
        state.groups.splice(index, 1, updatedGroup);
      }
    },
    setItemData(state, itemData) {
      state.itemData = itemData;
    },
  },
  
  actions: {
    async loginUser({ commit }, { name, password }) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/api/login`, { name, password });

        if (response.status === 200) {
          const token = response.data.token;

          // Mendekode token JWT untuk mendapatkan informasi pengguna
          const decodedToken = jwtDecode(token);
          if (decodedToken) {
            // Set isLoggedIn menjadi true dan simpan userInfo di state
            console.log('dec token', decodedToken);
            commit('setLoggedIn', true);

            // Simpan informasi pengguna dari token ke dalam state userInfo
            commit('setUserInfo', {
              user_id: decodedToken.user_id,
              username: decodedToken.username,
              role_id: decodedToken.role_id,
              role_name: decodedToken.role_name,
            });

            // Simpan token di localStorage jika diperlukan
            localStorage.setItem('token', token);

            return true;
          } else {
            throw new Error('Invalid token');
          }
        } else {
          throw new Error('Login failed');
        }
      } catch (error) {
        console.error('Login error:', error);
        return false;
      }
    },
    logoutUser({ commit }) {
      try {
        localStorage.removeItem('userInfo');
        commit('logout');
      } catch (error) {
        console.error('Logout error:', error);
      }
    },

    async fetchMaterialData({ commit, state }) {
      console.log('info login', state.userInfo);
      const roleId = state.userInfo.role_id;
      const userName = state.userInfo.username;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/material`, {
          params: { page: state.page, perPage: state.perPage, role: roleId, username: userName  }
        });

        console.log('response',response);
        const data = response.data.data;
  
        commit('SET_ITEM_DATA', data.materials);
        commit('SET_PAGE', state.page + 1);
      } catch (error) {
        console.error('Error fetching material data', error);
      }
    },

    async fetchAllData({ commit, state }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/allMaterial`, {
          params: { page: state.page, perPage: state.perPage }
        });
        const data = response.data.data;
        commit('SET_ALL_DATA', data)
  
      } catch (error) {
        console.error('Error fetching material data', error);
      }
    },
  
    async searchItems({ commit, state }, payload) {
      try {
        console.log('Search payload store', payload);
        const response = await axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/search`, {
          page: state.searchPage, perPage: state.perPage, payload
        });
        
        commit('SET_SEARCH_DATA', response.data.data.materials);
        commit('SET_SEARCH_PAGE', state.searchPage + 1);
        // await dispatch('fetchMaterialData');
      } catch (error) {
        console.error('Error searching items', error);
      }
    },

    async newSearchItem({commit, state}, payload) {
      try {
        commit('SET_SEARCH_PAGE', 1);
        const response = await axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/search`, {
          page: state.searchPage, perPage: state.perPage, payload
        });
        commit('RESET_SEARCH_DATA');
        commit('SET_SEARCH_DATA', response.data.data.materials);
        commit('SET_SEARCH_PAGE', state.searchPage + 1);
      } catch (error) {
        console.error('Error searching items', error);
      }
    },

    // async searchAllItems({ commit, state }, payload) {
    //   try {
    //     console.log('Search payload store', payload);
    //     const response = await axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/searchAll`, {
    //       page: state.searchPage, perPage: state.perPage, payload
    //     });
        
    //     commit('SET_SEARCH_ALL_DATA', response.data.data.materials);
    //     commit('SET_SEARCH_ITEMS_PAGE', state.searchPage + 1);
    //     // await dispatch('fetchMaterialData');
    //   } catch (error) {
    //     console.error('Error searching items', error);
    //   }
    // },

    // async newSearchAllItem({commit, state}, payload) {
    //   try {
    //     commit('SET_SEARCH_PAGE', 1);
    //     console.log('Search payload store', payload);
    //     const response = await axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/searchAll`, {
    //       page: state.searchPage, perPage: state.perPage, payload
    //     });
    //     commit('RESET_SEARCH_ALL_DATA');
    //     commit('SET_SEARCH_ALL_DATA', response.data.data.materials);
    //     commit('SET_SEARCH_ITEMS_PAGE', state.searchPage + 1);
    //   } catch (error) {
    //     console.error('Error searching items', error);
    //   }
    // },


    fetchIncData({ commit }) {      
      axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/inc`)
        .then(response => {
          const res = response.data
          commit('SET_INC_DATA', res.data)
        })
        .catch(() => {
          console.error('Error fetching inc data')
        })
    },
    fetchUserData({ commit }) {      
      axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/user`)
        .then(response => {
          const res = response.data
          commit('SET_USER_DATA', res.data)
        })
        .catch(() => {
          console.error('Error fetching user data')
        })
    },
    fetchUserRole({ commit }) {      
      axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/userRole`)
        .then(response => {
          const res = response.data
          commit('SET_USER_ROLE', res.data)
        })
        .catch(() => {
          console.error('Error fetching user data')
        })
    },
    fetchGroupData({ commit }) {      
      axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/group`)
        .then(response => {
          const res = response.data
          commit('SET_GROUPS', res.data)
        })
        .catch(() => {
          console.error('Error fetching group data:')
        })
    },
    fetchAttributeData({ commit }) {      
      axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/attribute`)
        .then(response => {
          const res = response.data
          commit('SET_ATTRIBUTES', res.data)
        })
        .catch(() => {
          console.error('Error fetching data:')
        })
    },
    fetchGlobalAttributeData({ commit }) {      
      axios.get(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/globalattribute`)
        .then(response => {
          const res = response.data
          commit('SET_GLOBAL_ATTRIBUTES', res.data)
        })
        .catch(() => {
          console.error('Error fetching global attribute')
        })
    },
    updateSelectedRow({ commit, state }, row) {
      console.log(state.selectedRow);
      commit('SET_SELECTED_ROW', row);
    },
    updateSelectedItem({ commit, state }, payload) {
      const { index, newItemData } = payload;

      // const updatedItem = state.itemData.find(item => item.id === index);
      const itemIndex = state.itemData.findIndex(item => item.id === index);
      // const rowDataIndex = state.selectedRow.findIndex(item => item.id === index);
      if (itemIndex !== -1) {
        // Mengganti data item dengan data dari newItemData
        const updatedItemData = [...state.itemData];
        updatedItemData[itemIndex] = { ...updatedItemData[itemIndex], ...newItemData };
        
        // Memperbarui state.itemData
        commit('setItemData', updatedItemData);
      }
      state.selectedRow[index] = newItemData
      
    //   if (updatedItem) {
    //     // Mengganti data item dengan data dari newItemData
    //     Object.assign(updatedItem, newItemData);

    //     // Memperbarui state.itemData
    //     const updatedItemData = [...state.itemData];
    //     commit('setItemData', updatedItemData);
    // }
      // const updatedSelectedRow = [...state.selectedRow];
      // updatedSelectedRow[index] = newItemData; // Mengganti item pada indeks yang ditentukan
      // commit('SET_SELECTED_ROW', updatedSelectedRow);
    },
    
    updateInc({ commit}, payload) {
      const {listId, inc} = payload
      // console.log("List, inc ---", listId, inc);
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/update-inc-by-ids`, {
        "inc": inc,
        "IDs": listId
      }).then(res=> {
        commit('BULK_UPDATE_INC', res.data)
      }).catch(()=> {
        console.error('Gagal Update Inc');
      })
    },
    updateMaterialCat({ commit}, payload) {
      const {listId, cat} = payload
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/update-cat-by-ids`, {
        "cat": cat,
        "IDs": listId
      }).then(res=> {
        commit('BULK_UPDATE_CAT', res.data)
      }).catch(() => {
        console.error('Gagal Update Cat');
      })
    },
    updateMaterialQc({ commit}, payload) {
      const {listId, qc} = payload
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/update-qc-by-ids`, {
        "qc": qc,
        "IDs": listId
      }).then(res=> {
        commit('BULK_UPDATE_QC', res.data)
      }).catch(()=> {
        console.error('Gagal Update qc');
      })
    },
    updateMaterialStatus({ commit}, payload) {
      const {listId, status} = payload
      console.log("List, Status ---", listId, status);
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/update-status-by-ids`, {
        "status": status,
        "IDs": listId
      }).then(res=> {
        commit('BULK_UPDATE_STATUS', res.data)
      }).catch(() => {
        console.error('Gagal Update status');
      })
    },
    updateMaterialData({ commit }, data) {
      axios.put(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/material/${data.id}`, {
        "materialNumber": data.materialNumber,
        "partNumber": data.partNumber,
        "rawData": data.rawData,
        "rawData2": data.rawData2,
        "rawData3": data.rawData3,      
        "rawData4": data.rawData4,
        "flag1": data.flag1,
        "flag2": data.flag2,
        "result": data.result,
        "attribute_value": data.attributeValue,
        "global_attribute_value": data.globalAttributeValue,
        "inc": data.inc,
        "mfr": data.mfr,
        "groupCode": data.groupCode,   
        "cat": data.cat,
        "status": data.status, 
        "link": data.link,
        "history": data.history
      },{
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        commit('UPDATE_MATERIAL_DATA', response.data)
      })
      .catch(() => {
        console.error('Gagal Update Data Material');
      })
    },
    setButtonAndContent({ commit }, { buttonName, contentName }) {
        commit('setActiveButton', buttonName);
        commit('setActiveContent', contentName);
    },
    saveItemValue({ commit }, { itemId, attribute, value }) {
      commit('updateItemValue', { itemId, attribute, value });
    },

    async createAttribute({ commit }, payload) {
      const { inc, attributeCode, attributeName, sequence } = payload.attribute;
      return axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/attribute`, {
        "INC": inc,
        "attribute_code": attributeCode,
        "attribute_name": attributeName,
        "sequence": sequence,
      }).then(res => {
        commit('ADD_NEW_ATTRIBUTE', res.data);
        return { success: true, message: 'Attribute Baru Berhasil Tersimpan' };
      }).catch((err) => {
        console.log(err.response.data.messages.error);
        const errMessage = err.response.data.messages.error;
        return { success: false, message: `Gagal Membuat Attribute Baru, ${errMessage}` };
      });
    },
    

    bulkInsertAttribute({commit}, payload) {
      const {file} = payload
      try {
        const formData = new FormData();
        formData.append('excel_file', file);

        axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/attribute/bulkInsert`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          commit('BULK_INSERT_ATTRIBUTE', res.data)
        }).catch(() => {
          console.error('Gagal Create ATTRIBUTE');
        })
      } catch (error) {
        const errMsg = error.response.data.message
        console.log(errMsg);
      }
      this.saveClicked = false
    },
    updateAttribute({commit}, payload) {
      const {attribute} = payload
      console.log('payload',payload);
      axios.put(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/attribute/${parseInt(attribute.id)}`, {
        "INC": attribute.inc,
        "attribute_code": attribute.attributeCode,
        "attribute_name": attribute.attributeName,
        "sequence" : attribute.sequence
      }).then(() => {
        commit('UPDATE_MASTER_ATTRIBUTE', attribute)
      })
      .catch(()=> {
        console.error('Gagal Update Attribute');
      })
    },
    deleteAttribute({commit}, payload) {
      axios.delete(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/attribute/${payload}`)
      .then((res) => {
        console.log('after delete',res.data);
        commit("DELETE_MASTER_ATTRIBUTE", payload)
      }).catch((err)=> {
        console.error('Gagal Menghapus Attribute', err);
      })
    },

    createGlobal({commit}, payload) {
      const {globalCode, globalName} = payload.globalData
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/globalattribute`, {
        "attribute_code": globalCode,
        "attribute_name": globalName,
      }).then(res=> {
        commit('ADD_NEW_GLOBAL', res.data)
      }).catch(() => {
        console.error('Gagal Create Global');
      })
    },
    bulkInsertGlobal({commit}, payload) {
      const {file} = payload.file
      try {
        const formData = new FormData();
        formData.append('excel_file', file);

        axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/globalattribute/bulkInsert`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          commit('BULK_INSERT_GLOBAL', res.data)
        }).catch(() => {
          console.error('Gagal Create Global');
        })
      } catch (error) {
        const errMsg = error.response.data.message
        console.log(errMsg);
      }
      this.saveClicked = false
    },
    updateMasterGlobal({commit}, payload) {
      const {global} = payload
      console.log('payload', payload);
      axios.put(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/globalattribute/${parseInt(global.id)}`, {
        "attribute_code": global.globalattributeCode,
        "attribute_name": global.globalattributeName,
      }).then(() => {
        commit('UPDATE_MASTER_GLOBAL', payload.global)
      })
      .catch(()=> {
        console.error('Gagal Update Global');
      })
    },
    deleteGlobal({commit}, payload) {
      console.log('payload', payload);
      axios.delete(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/globalattribute/${payload}`)
      .then((res) => {
        console.log('after delete',res.data);
        commit("DELETE_MASTER_GLOBAL", payload)
      }).catch((err)=> {
        console.error('Gagal Menghapus Global', err);
      })
    },

    async createInc({ commit }, payload) {
      const { inc, incName } = payload.incData;
      console.log('payload', payload);
      return axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/inc`, {
        "INC": inc,
        "INC_NAME": incName,
      }).then(res => {
        commit('ADD_NEW_INC', res.data);
        return { success: true, message: 'INC Baru Berhasil Tersimpan' };
      }).catch(err => {
        console.log(err.response.data.messages.error);
        const errMessage = err.response.data.messages.error
        return { success: false, message: `Gagal Membuat INC Baru, ${errMessage}` };
      });
    },  

    bulkInsertInc({commit}, payload) {
      const {file} = payload
      try {
        const formData = new FormData();
        formData.append('excel_file', file);

        axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/inc/bulkInsert`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          commit('BULK_INSERT_INC', res.data)
        }).catch(() => {
          console.error('Gagal Create INC');
        })
      } catch (error) {
        const errMsg = error.response.data.message
        console.log(errMsg);
      }
      this.saveClicked = false
    },
    updateMasterInc({commit}, payload) {
      const {inc} = payload
      console.log('payload', payload);
      axios.put(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/inc/${parseInt(inc.id)}`, {
        "INC": inc.inc,
        "INC_NAME": inc.incName,
      }).then(() => {
        commit('UPDATE_MASTER_INC')
      })
      .catch(()=> {
        console.error('Gagal Update INC');
      })
    },
    deleteInc({commit}, payload) {
      axios.delete(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/inc/${payload}`)
      .then((res) => {
        console.log('after delete',res.data);
        commit("DELETE_MASTER_INC", payload)
      }).catch((err)=> {
        console.error('Gagal Menghapus Inc', err);
      })
    },

    createGroup({commit}, payload) {
      const {groupCode, groupName} = payload.groupData
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/group`, {
        "group_code": groupCode,
        "group_name": groupName,
      }).then(res=> {
        commit('ADD_NEW_GROUP', res.data)
      }).catch(() => {
        console.error('Gagal Create Group');
      })
    },
    bulkInsertGroup({commit}, payload) {
      const {file} = payload
      try {
        const formData = new FormData();
        formData.append('excel_file', file);

        axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/group/bulkInsert`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          commit('BULK_INSERT_GROUP', res.data)
        }).catch(() => {
          console.error('Gagal Create Group');
        })
      } catch (error) {
        const errMsg = error.response.data.message
        console.log(errMsg);
      }
      this.saveClicked = false
    },
    updateMasterGroup({commit}, payload) {
      const {group} = payload
      axios.put(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/group/${parseInt(group.id)}`, {
        "group_code": group.groupCode,
        "group_name": group.groupName,
      }).then(() => {
        commit('UPDATE_MASTER_GROUP', payload.group)
      })
      .catch(()=> {
        console.error('Gagal Update Group');
      })
    },
    deleteGroup({commit}, payload) {
      console.log('payload', payload);
      axios.delete(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/group/${payload}`)
      .then((res) => {
        console.log('after delete',res.data);
        commit("DELETE_MASTER_GROUP", payload)
      }).catch((err)=> {
        console.error('Gagal Menghapus Group', err);
      })
    },

    createUser({commit}, payload) {
      const {userData} = payload
      console.log('payload', payload);
      axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/user`, {
        "name": userData.name,
        "password": userData.password,
        "role_id": userData.role_id,
      }).then(res=> {
        commit('ADD_NEW_USER', res.data)
      }).catch(() => {
        console.error('Gagal Create User');
      })
    },
    bulkInsertUser({commit}, payload) {
      const {file} = payload
      try {
        const formData = new FormData();
        formData.append('excel_file', file);

        axios.post(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/user/bulkInsert`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          commit('BULK_INSERT_USER', res.data)
        }).catch(() => {
          console.error('Gagal Create User');
        })
      } catch (error) {
        const errMsg = error.response.data.message
        console.log(errMsg);
      }
      this.saveClicked = false
    },
    updateMasterUser({commit}, payload) {
      const {user} = payload
      axios.put(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/user/${parseInt(user.id)}`, {
        "name": user.name,
        "password": user.password,
      }).then(() => {
        commit('UPDATE_MASTER_USER', payload.user)
      })
      .catch(()=> {
        console.error('Gagal Update user');
      })
    },
    deleteUser({commit}, payload) {
      console.log('payload', payload);
      axios.delete(`${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_PORT}/user/${payload}`)
      .then((res) => {
        console.log('after delete',res.data);
        commit("DELETE_MASTER_GROUP", payload)
      }).catch((err)=> {
        console.error('Gagal Menghapus Group', err);
      })
    },
  },
  
  getters: {
    isLoggedIn: (state) => !!state.userInfo,

    getItemById: (state) => (itemId) => {
      return state.itemData.find(item => item.id === itemId);
    },
    getItemData: (state) => {
      return state.itemData
    },

    getAllData: (state) => {
      return state.allDatas
    },

    getSearchData: (state) => {
      return state.searchData
    },
    getSearchDataAll: (state) => {
      return state.searchDataAll
    },
    getOffset: (state) => state.offset,
    getLimit: (state) => state.limit,
    getIncData: (state) => {
      return state.inc
    },
    getAllUser: (state) => {
      return state.user
    },
    getUserRole: (state) => {
      return state.userRole
    },
    getUserCataloguer: state => {
      if(state.user && state.user.length > 0) {
        const cataloguerUsers = state.user.filter(user => user.userRoleId == 4);
        return cataloguerUsers;
      }
      return null;
    },
    getUserQc: state => {
      if(state.user && state.user.length > 0) {
        const qcUsers = state.user.filter(user => user.userRoleId == 2);
        return qcUsers;
      }
      return null;
    },    
    getAttributes: state => {
      return state.attributes;
    },
    getGlobalAttributes: state => {
      return state.globalAttributes;
    },
    getGroupData: state => {
      return state.groups;
    },
    getSelectedRow: (state) => {
      return state.selectedRow;
    },
    isSearchingData: (state) => {
      return state.isSearching
    }
  },
});

export default store;
