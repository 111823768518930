<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-fit w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col py-2 h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Add New INC</p>
                </div>
                <hr/>

                <form class="max-w-sm mx-auto" @submit.prevent="addInc">
                    <div>
                        <div class="mb-5">
                            <label for="inc_code" class="block mb-2 text-sm font-medium text-gray-900">INC</label>
                            <input type="text" id="inc_code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="INC" v-model="inc" required>
                        </div>
                        <div class="mb-5">
                            <label for="inc_name" class="block mb-2 text-sm font-medium text-gray-900">INC Name</label>
                            <input type="text" id="inc_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="INC Name" v-model="incName" required>
                        </div>
                    </div>
            
                    <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    data() {
        return {
            inc: '',
            incName: '',
        };
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                // this.$store.dispatch('updateMaterialData', this.localFlagData)
                this.$emit('close');
            }
        },
        // addInc() {
        //     console.log('Buat INC Baru', this.inc, this.incName);
        //     try{
        //         const incData = {
        //             inc: this.inc,
        //             incName: this.incName
        //         }
        //         // const listId = this.selectedRow.map(objek => objek.id);
        //         this.$store.dispatch('createInc', { incData });
        //         this.$toast.success('INC Baru Berhasil Tersimpan', {
        //             duration: 2500,
        //             position: 'top-right'
        //         });
        //     }
        //     catch(error){
        //         this.$toast.error(`Gagal Membuat INC Baru, ${error}`, {
        //             duration: 2500,
        //             position: 'top-right'
        //         });
        //     }
        // }

        async addInc() {
            console.log('Buat INC Baru', this.inc, this.incName);
            try {
                const incData = {
                inc: this.inc,
                incName: this.incName
                };
                const result = await this.$store.dispatch('createInc', { incData });
                if (result.success) {
                this.$toast.success(result.message, {
                    duration: 2500,
                    position: 'top-right'
                });
                } else {
                this.$toast.error(result.message, {
                    duration: 2500,
                    position: 'top-right'
                });
                }
            } catch (error) {
                this.$toast.error(`Gagal Membuat INC Baru, ${error.message}`, {
                duration: 2500,
                position: 'top-right'
                });
            }
        }
    },
}
</script>