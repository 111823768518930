<template>
    <div class="flex flex-col h-screen">
        <div class=" h-full w-full bg-[#fffaee]">
            <div v-if="isLoggedIn" class="flex justify-end text-white px-4 text-md py-2 font-medium bg-slate-500">
                {{ $store.state.userInfo.username&&$store.state.userInfo.username.toUpperCase() }} ({{ $store.state.userInfo.role_name&&$store.state.userInfo.role_name.toUpperCase() }})
            </div>
            <div v-if="itemData.length > 0" class="flex flex-row gap-5">
                <div class="flex flex-row gap-2 justify-center items-center">
                    <button class="bg-gradient-to-r from-red-500 to-red-700 w-32 my-2 ml-2 px-2 py-1 text-sm font-semibold rounded-sm text-white" @click="expandTable=!expandTable">{{expandTable? 'Perkecil Tabel' : 'Tampilan Penuh'}}</button>

                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" v-model="highlightEnabled" class="sr-only peer">
                        <div class="w-11 h-6 bg-red-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-red-600"></div>
                        <span class="ml-3 text-xs font-medium text-gray-900">Compare</span>
                    </label>
                </div>
                <!-- Search Form -->
                <form class="flex flex-col" @submit.prevent="searchItems">
                    <div class="flex flex-row gap-2">
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchNumber" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Material Number">
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="searchInc" v-model="searchInc" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="INC / INC Name">
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="searchBefore" v-model="searchBefore" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Description (BEFORE)">
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="searchAfter" v-model="searchAfter" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Description (AFTER)">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchPartNumber" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Part Number">
                        </div>
                    </div>
                    <div class="flex flex-row gap-2">
                        
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchMfr" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Manufacturer">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchFlag1" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Flag 1">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchStatus" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Status">
                        </div>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" id="search" v-model="searchAll" class="block w-full py-1 px-2 my-2 pl-10 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Semua Kolom">
                        </div>
                        <div class="flex items-center">
                            <button type="submit" class="py-1 px-2 my-2 bg-green-600 rounded-sm text-white font-semibold">Cari</button>
                        </div>
                        <div class="flex items-center">
                            <button type="button" @click="resetFilters" class="py-1 px-2 my-2 bg-red-600 rounded-sm text-white font-semibold">Reset</button>
                        </div>
                    </div>
                </form>

                <div class="relative ml-auto px-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    </div>
                    <input type="search" id="highlight" v-model="highlightText" class="block w-full py-1 px-2 my-2 text-sm text-gray-900 border-2 border-red-400 rounded-sm bg-red-50 focus:ring-red-900 focus:outline-none focus:border-red-600 placeholder:text-gray-500" placeholder="Highlight Kata (After)">
                </div>
            </div>

            <div ref="scroll" @scroll="handleScroll" class="flex justify-start px-1 max-h-[calc(100vh-150px)] overflow-x-auto">
                <table class="w-[calc(100vw-60px)] text-left font-courier text-gray-800 font-medium text-[12px]">
                    <thead class="text-gray-100 uppercase bg-gray-700 sticky top-0">
                        <tr>
                            <th class="px-1 py-1 leading-none border-y border-gray-400">NO</th>
                            <th class="px-1 py-1 leading-none border-y border-gray-400">INC</th>
                            <th class="px-1 py-1 leading-none border-y border-gray-400">INC DESC</th>
                            <th class="px-1 py-1 leading-none border-y border-gray-400">DESCRIPTION (BEFORE)</th>
                            <th class="px-1 py-1 leading-none border-y border-gray-400">DESCRIPTION (AFTER)</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">GRP</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">GROUP DESC</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">PART NUMBER</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">MFR</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">FLAG 1</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">FLAG 2</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">RAW DATA 2</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">RAW DATA 3</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">RAW DATA 4</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">LINK</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">CAT</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">QC</th>
                            <th v-if="expandTable" class="px-1 py-1 leading-none border-y border-gray-400">STATUS</th>
                        </tr>
                    </thead>
                    <tbody class="bg-gray-50">
                        <tr v-for="data in showData" :key="data.id" class="cursor-default hover:bg-blue-200">
                            <td class="px-1 py-1 leading-none border-y border-gray-400">{{ data.materialNumber }}</td>
                            <td class="px-1 py-1 leading-none border-y border-gray-400">{{ data.inc }}</td>
                            <td class="px-1 py-1 leading-none border-y border-gray-400">{{ data.incName}}</td>
                            <td class="px-1 py-1 leading-none border-y border-gray-400">
                                <span v-if="data.rawData !== null || data.result !== null" v-html="highlightMissingWords(data.rawData, data.result)"></span>
                            </td>
                            <td class="px-1 py-0 leading-none border-y border-gray-400">
                                <div v-if="!highlightEnabled && data.result !== null" v-html="highlightDescriptionAfter(data)"></div>
                                        <span v-if="highlightEnabled && data.rawData !== null && data.result !== null" v-html="highlightMissingWords(data.result, data.rawData)"></span>
                            </td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.groupCode }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.groupName }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.partNumber }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.mfr }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.flag1 }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.flag2 }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.rawData2 }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.rawData3 }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.rawData4 }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.link }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.cat }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.qc }}</td>
                            <td v-if="expandTable" class="px-1 py-0 leading-none border-y border-gray-400">{{ data.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ItemByUser',
    data() {
        return {
            materialData: null,
            selectedRow: [],
            selectedDesc:'',
            selectedDescAfter:'',
            expandTable:false,
            
            searchNumber:'',
            searchInc:'',
            searchBefore:'',
            searchAfter:'',
            searchPartNumber:'',
            searchMfr:'',
            searchFlag1:'',
            searchStatus: '',
            searchAll: '',

            highlightText:'',
            highlightEnabled: false,
            selectedNumber: '',
            currentPage: 1,
            // itemsPerPage: 99999999999,
        }
    },
    methods: {
        handleScroll() {
            const scrollElement = this.$refs.scroll;
            if (Math.round(scrollElement.scrollTop + scrollElement.clientHeight) >= scrollElement.scrollHeight) {
                // Cek apakah ada pencarian yang dilakukan
                if (this.searchData.length > 0) {
                // Panggil action pencarian dengan parameter pencarian
                this.$store.dispatch('searchItems', {
                    searchNumber: this.searchNumber,
                    searchInc: this.searchInc,
                    searchBefore: this.searchBefore,
                    searchAfter: this.searchAfter,
                    searchPartNumber: this.searchPartNumber,
                    searchMfr: this.searchMfr,
                    searchFlag1: this.searchFlag1,
                    searchStatus: this.searchStatus,
                    searchAll: this.searchAll,
                });
                } else {
                this.$store.dispatch('fetchMaterialData');
                }
            }
        },
        async searchItems() {
            this.isSearching = true;

            this.$store.commit('SET_PAGE', 1);

            const payload = {
                searchNumber: this.searchNumber,
                searchInc: this.searchInc,
                searchBefore: this.searchBefore,
                searchAfter: this.searchAfter,
                searchPartNumber: this.searchPartNumber,
                searchMfr: this.searchMfr,
                searchFlag1: this.searchFlag1,
                searchStatus: this.searchStatus,
                searchAll: this.searchAll,
            };
             console.log('search payload', payload);

            try {
                await this.$store.dispatch('newSearchItem', payload);
            } catch (error) {
                console.error('Error searching items', error);
            } finally {
                this.isSearching = false;
            }
        },
        resetFilters() {
            this.searchNumber = '';
            this.searchInc = '';
            this.searchBefore = '';
            this.searchAfter = '';
            this.searchPartNumber = '';
            this.searchMfr = '';
            this.searchFlag1 = '';
            this.searchStatus = '';
            this.searchAll = '';

            this.searchItems();
        },
        highlightMissingWords(source, target) {
            const sourceWords = source?.split(/[ ,;]+/) || [];
            const targetWords = target?.split(/[ ,;]+/) || [];

            if (this.highlightEnabled && sourceWords.length > 0 && targetWords.length > 0) {
                const highlightedWords = sourceWords.slice();
                for (let i = 0; i < sourceWords.length; i++) {
                if (!targetWords.includes(sourceWords[i])) {
                    highlightedWords[i] = `<span class="text-slate-200 bg-red-400">${sourceWords[i]}</span>`;
                }
                }
                return highlightedWords.join(' ');
            } else {
                return source || '';
            }
        },
        highlightDescriptionAfter(data) {
            if (!data || !data.result || this.highlightText.trim() === "") {
                return data.result;
            }

            const keywords = this.highlightText.toLowerCase().split(/\s+/).filter(keyword => keyword.trim() !== "");

            let descriptionAfter = data.result;

            keywords.forEach(keyword => {
                const regex = new RegExp(keyword, "gi");
                descriptionAfter = descriptionAfter.replace(regex, match => {
                return `<span class="text-slate-200 bg-red-400">${match}</span>`;
                });
            });

            return descriptionAfter;
        },
        clickPaginate (pageNum) {
            this.currentPage = pageNum;
        }
    },
    computed: {
        searchData() {
            return this.$store.getters.getSearchData;
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        itemData() {
           return this.$store.getters.getItemData
        },
        
        totalItems() {
            return this.filteredDataAll.length;
        },
        showData() {
            if(this.$store.getters.isSearchingData){
                return this.searchData
                // return this.searchData.length>0 ? this.searchData : this.itemData
            } else {
                return this.itemData
            }
        },
        
    },
    mounted(){
        this.$store.dispatch('fetchMaterialData')
    }
}
</script>