<template>
    <div @click.self="closeModal" class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div @click.stop class="h-fit w-2/5 bg-white flex flex-col items-center justify-between p-3">
            <div class="flex flex-col py-2 h-full w-full gap-2">
                <div>
                    <p class="text-lg font-bold text-gray-700 mb-1 text-center">Add New Attribute</p>
                </div>
                <hr/>

                <form class="max-w-sm mx-auto" @submit.prevent="addAttribute">
                    <div class="mb-5">
                        <label for="inc" class="block mb-2 text-sm font-medium text-gray-900">Inc</label>
                        <p v-if="selectedInc">{{selectedInc.INC}} - {{selectedInc.INCName}}</p>
                        <button @click="openInc" class="rounded-sm px-2 py-0 w-3/4 bg-blue-600 text-white disabled:bg-slate-200 disabled:text-slate-400">Pilih Inc</button>
                        <!-- <select name="" id="" class="bg-gray-100 w-64" v-model="selectedInc">
                            <option value="" selected disabled>Pilih INC Code</option>
                            <option v-for="item in incData" :key="item.id" :value="item.INC">{{ item.INC }} - {{item.INCName}}</option>
                        </select> -->
                    </div>
                    <!-- <div v-if="selectedInc"> -->
                        <div class="mb-5">
                            <label for="attr_code" class="block mb-2 text-sm font-medium text-gray-900">Attribute Code</label>
                            <input type="text" id="attr_code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Attribute Code" v-model="attributeCode" required>
                        </div>
                        <div class="mb-5">
                            <label for="attr_name" class="block mb-2 text-sm font-medium text-gray-900">Attribute Name</label>
                            <input type="text" id="attr_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Attribute Name" v-model="attributeName" required>
                        </div>
                        <div class="mb-5">
                            <label for="sequence" class="block mb-2 text-sm font-medium text-gray-900">Sequence</label>
                            <input type="number" id="sequence" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Sequence" v-model="sequence" required>
                        </div>
                    <!-- </div> -->
            
                    <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">Submit</button>
                </form>
            </div>
        </div>        
    </div>
    <modal-inc v-if="openModalInc" :data="selectedInc" @closeModalInc="closeModalInc" @saveAndCloseModalInc="saveAndCloseModalInc" />
</template>
<script>
import modalInc from '@/utils/masterdata/attribute/ModalInc.vue'
export default {
    data() {
        return {
            selectedInc: null,
            attributeCode: '',
            attributeName: '',
            sequence: '',
            openModalInc: false
        };
    },
    components: {
        modalInc
    },
    methods: {
        closeModal(event) {
            if (event.target.classList.contains('bg-gray-900')) {
                this.$emit('close');
            }
        },

        async addAttribute() {
            try{
                const attribute = {
                    inc: this.selectedInc.INC,
                    attributeCode: this.attributeCode,
                    attributeName: this.attributeName,
                    sequence: this.sequence,
                }
                // const listId = this.selectedRow.map(objek => objek.id);
                const result = await this.$store.dispatch('createAttribute', { attribute });
                console.log('result',result);
                 if (result.success) {
                this.$toast.success(result.message, {
                    duration: 2500,
                    position: 'top-right'
                });
                } else {
                this.$toast.error(result.message, {
                    duration: 2500,
                    position: 'top-right'
                });
                }
            }
            catch(error){
                console.log('error',error);
                this.$toast.error(`Gagal Menyimpan Attribute Baru, ${error.message}`, {
                    duration: 2500,
                    position: 'top-right'
                });
            }
        },
        openInc(){
            this.openModalInc = true;
        },
        closeModalInc() {
            this.openModalInc = false;
            // window.location.reload();
        },
        saveAndCloseModalInc(data) {
            console.log('selectedIncItemList', data); // data: INC, INCName
            // this.updateInc(data.INC)
            // this.updateInc(data)
            this.selectedInc = data
            //
            //
            this.openModalInc = false;
        },
    },
    computed: {
        incData() {
            return this.$store.getters.getIncData
        },
    }
}
</script>